<template>
  <div>
  <b-card>
    <h2 class="mb-5">
      الاجازات
    </h2>
   

    <b-row v-for="item in Leaves">
      <b-col
        cols="6"
        md="6"
      >
        <b-form-group
          label="المحتوى"
          label-for="username"
        >
          <b-form-input
            id="username"
            v-model="item.body"
            disabled
          />
        </b-form-group>
      </b-col>

      <!-- Field: Full Name -->
      <b-col
        cols="6"
        md="6"
      >
        <b-form-group
          label="التاريخ"
          label-for="full-name"
        >
          <b-form-input
            id="full-name"
            v-model="item.start"
            disabled
          />
        </b-form-group>
      </b-col>
      <!-- latest photo loop -->

      <!-- latest photo loop -->
    </b-row>
    <div class="d-flex mt-2">
      <b-button

        variant="primary"
        class="mr-2"
        @click="$router.push({name:'leaves'})"
      >
        <span>تقديم اجازة</span>
      </b-button>

    </div>
  </b-card>
  <b-card>
    <h2 class="mb-5">
      العطل
    </h2>

    <b-row v-for="item in profileData">
      <b-col
        cols="6"
        md="6"
      >
        <b-form-group
          label="العنوان"
          label-for="username"
        >
          <b-form-input
            id="username"
            v-model="item.title"
            disabled
          />
        </b-form-group>
      </b-col>

      <!-- Field: Full Name -->
      <b-col
        cols="6"
        md="6"
      >
        <b-form-group
          label="التاريخ"
          label-for="full-name"
        >
          <b-form-input
            id="full-name"
            v-model="item.date"
            disabled
          />
        </b-form-group>
      </b-col>
      <!-- latest photo loop -->

      <!-- latest photo loop -->
    </b-row>
  
  </b-card>



  <b-card>
    <h2 class="mb-5">
     العهد
    </h2>
   

    <b-row v-for="item in pacts">
      <b-col
        cols="6"
        md="6"
      >
        <b-form-group
          label="اسم المادة"
          label-for="username"
        >
        {{ item.item_name}}
          <!-- <b-form-input
            id="username"
            v-model="item"
            disabled
          /> -->
        </b-form-group>
      </b-col>

      <!-- Field: Full Name -->
      <b-col
        cols="6"
        md="6"
      >
        <b-form-group
          label="التاريخ"
          label-for="full-name"
        >
          <b-form-input
            id="full-name"
            v-model="item.date"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="6"
      >
        <b-form-group
          label="الملف"
          label-for="full-name"
        >
        <a
          target="_blank"
            :href="item.file">
            <feather-icon icon="LinkIcon" />
            عرض الملف
          </a>
        </b-form-group>
      </b-col>
      <!-- latest photo loop -->

      <!-- latest photo loop -->
    </b-row>
   
  </b-card>
</div>
</template>

<script>
import {
  BCard, BRow, BCol, BLink, BImg, BButton, BFormInput, BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BLink,
    BImg,
    BFormInput,
    BFormGroup,
  },
  data() {
    return {
      profileData: [],
      Leaves: [],
      pacts:[]
    }
  },
  created() {
    this.$http.get('/api/v1/holidays').then(res => {
  //    console.log(res.data)

      this.profileData = res.data

   //   console.log(this.profileData)
    })
    this.$http.get('/api/v1/get-user-pacts').then(res => {
   //   console.log(res.data)

      this.pacts = res.data.data

    //  console.log(this.profileData)
    })
    this.$http.get('/api/v1/get-user-leaves').then(res => {
     // console.log(res.data)

      this.Leaves = res.data.data

    //  console.log(this.profileData)
    })
  },
}

</script>
