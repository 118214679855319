<template>
  <div>
    <b-form
      class="p-2"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
      v-for="(Leave, i) in Leaves"
      :key="i"
    >
      <!-- Full Name -->
      <validation-provider
        #default="validationContext"
        name="Leave-date"
        rules="required"
      >
        <b-form-group label="Leave Date" label-for="Leave Date">
          <flat-pickr
            v-model="Leave.date"
            class="form-control"
            name="date"
            placeholder="Birth date"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
        #default="validationContext"
        name="Leave Type"
        rules="required"
      >
        <b-form-group label="Leave Type" label-for="Leave-Type">
          <v-select v-model="Leave.leaveType" />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <!-- Full Name -->
      <validation-provider #default="validationContext" name="Reason" rules="required">
        <b-form-group label="Reason" label-for="Reason">
          <b-form-input id="full-name" v-model="Leave.reason" autofocus trim />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-form>
    <!-- Form Actions -->
    <div class="d-flex mt-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="MoreLeave"
      >
        More
      </b-button>
    </div>

    <div class="d-flex mt-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-2"
        type="submit"
      >
        Add
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="outline-secondary"
      >
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
import { BAvatar, BListGroup, BListGroupItem, BCardText } from "bootstrap-vue";
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
export default {
  components: {
    BAvatar,
    BListGroup,
    vSelect,
    flatPickr,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroupItem,
    BCardText,
  },

  data() {
    return {
      Leaves: [
        {
          date: "",
          reason: "",
          leaveType: "",
        },
      ],
    };
  },

  methods: {
    MoreLeave() {
      this.Leaves.push({
        date: "",
        reason: "",
        leaveType: "",
      });
    },
  },
  directives: {
    Ripple,
  },
};
</script> 

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>