<template>
<b-card>
   <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="FileIcon"
            size="18"
          />
          <span class="align-middle ml-50">حساب البنك</span>
        </b-card-title>
      </b-card-header>
      <br><br>
  <b-form>
    <b-row>
    <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Account Holder Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
            
            />
          </b-form-group>
        </b-col>
         <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Account Number"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
            
            />
          </b-form-group>
        </b-col>

         <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Blank Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
            
            />
          </b-form-group>
        </b-col>
          <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="IfCS Code"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
            
            />
          </b-form-group>
        </b-col>
           <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Pan Number"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
            
            />
          </b-form-group>
        </b-col>
           <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Branch"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
            
            />
          </b-form-group>
        </b-col>
</b-row>

  </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BCardHeader,
  
  BCardTitle, BFormInput, BButton,BCard, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
export default {
  components: {
    BRow, BCol,BCard, BCardHeader,
  
  BCardTitle, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
  },
  setup() {
   

    // ? dataField is field name of `userDataSocial` object
    

    return {
      
    }
  },
}
</script> 

<style>

</style>