import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import Vue from "vue";
import axios from "@axios";

export default function useUsersList() {
  


  const genderOptions = [{ label: "ذكر" }, { label: "أنثى" }];
  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: " الرقم", sortable: true },
    { key: "first_name", label: "الاسم", sortable: true },
    { key: "last_name", label: "النسبة", sortable: true },
    { key: "username", label: "اسم المستخدم", sortable: true },
    { key: "hire_date", label: "تاريخ التوظيف", sortable: true },

    { key: "role", label: "الدور", sortable: true },
    {
      key: "main_service.name",
      label: "القسم",
      formatter: title,
      sortable: true,
    },

    { key: "actions", label: "الخيارات" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );
  const getServiceType = () => {
       
        store.dispatch('app-user/getServType')
        .then((res) => {
        
        })
    
    }
  const fetchUsers = (ctx, callback) => {
    store
      .dispatch("app-user/fetchUsers",{
        page_size: perPage.value,
        name: searchQuery.value,
        page: currentPage.value,
      })
      .then((response) => {
        const { data ,total} = response.data;

        callback(data);
        totalUsers.value = total;
      })
      .catch(() => {
        Vue.swal({
          title: "حدثت مشكلة في استرجاع البيانات",
  icon: "error",
       
    confirmButtonText: "موافق",
    customClass: {
      confirmButton: "btn btn-primary",
    },
    buttonsStyling: false,
  });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "Information Manager") return "success";
    if (role === "Data Entry") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "Data Entry") return "Edit2Icon";
    if (role === "Information Manager") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === 0) return "warning";
    if (status === "active") return "success";
    if (status === 1) return "secondary";
    return "primary";
  };

  return {
    fetchUsers,
    genderOptions,
    getServiceType,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
